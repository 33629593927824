let searchWrap = document.querySelector('.menu__button-wrapper');
let arrIcon = document.querySelectorAll('.menu__search');
let bodyTag = document.querySelector('body');

searchWrap.addEventListener("click", function(e) {
	switchIcon(arrIcon);
	toggleClass(indieNewsPopUp);

	// Запрещает перемотку окна во время открытого поиска
	bodyTag.classList.toggle('_lock2');
});

function switchIcon(arrayIcons) {
	for (let index = 0; index < arrayIcons.length; index++) {
		const element = arrayIcons[index];
		element.classList.toggle('_active');
	}
}


//Popups - строка поиска

let indieNewsPopUp = document.querySelector('.indieNews_popup');
let iconMenu2 = document.querySelector('.menu__icon');
let iconLens = document.querySelector('.menu__search_lens');
let searchOverlay = document.querySelector('.search-overlay');

let menu = document.querySelector('.menu');
let hightOfMenu = menu.offsetHeight;
let hightAdBeforeMenu = 0;
if (document.querySelector('.banner__before-header')) {
	hightAdBeforeMenu = document.querySelector('.banner__before-header').offsetHeight;
};

function removeClass(block) {
	if (block.classList.contains('_active')) {
		block.classList.remove('_active');
	}	
}

function toggleClass(block) {
	block.classList.toggle('_active');	
}

iconMenu2.addEventListener("click", function(e) {
	removeClass(indieNewsPopUp);	
	if (iconLens.classList.contains('_active')) switchIcon(arrIcon);	
	
	// Запрещает перемотку окна во время открытого поиска
	if (bodyTag.classList.contains('_lock2')) bodyTag.classList.remove('_lock2');			
});

// Создание отступа строки поиска от меню
function putThePaddingOverlay(params) {
	if (document.documentElement.clientWidth < 435) {
		searchOverlay.style.top = hightAdBeforeMenu + hightOfMenu - 10 + 'px';		
	} else {
		searchOverlay.style.top = hightAdBeforeMenu + hightOfMenu +'px';
	}
	
}
putThePaddingOverlay();