// Lazy Load function

let lazyImages = document.querySelectorAll('img[data-src],source[data-srcset]');
// Определяем высоту окна просмотра
let windowHeight = document.documentElement.clientHeight;
// let loadMapBlock = document.querySelector('.техКлассКарты');
let loadMoreBlock = document.querySelector('._load-more');

let lazyImagesPositions = [];
if (lazyImages.length > 0) {
    lazyImages.forEach(img => {
        if (img.dataset.src || img.dataset.srcset) {
            // Вставляем положение каждого изображения в позиции страницы сайта
            lazyImagesPositions.push(img.getBoundingClientRect().top + pageYOffset);
            lazyScrollCheck();
        }
    });
}

// Слушаем скролл
window.addEventListener('scroll', lazyScroll);

function lazyScroll() {
    // Поиск по изображениям которые не были показаны
    if (document.querySelectorAll('img[data-src],source[data-srcset]').length > 0) {
        lazyScrollCheck();
    }
}


function lazyScrollCheck() {
    let imgIndex = lazyImagesPositions.findIndex(
        // Ищем индекс элемента, по позиции его положения на странице: количество прокрученных пикселей > позиция элемента - высота окна просмотра браузера
        item => pageYOffset > item - windowHeight
    );
    // Если, что-то найдено, то делаем проверку и вставляем в изначальные аттрибуты src данные местоположения картинки и удаляем новые аттрибуты data-src
    if (imgIndex >= 0) {
        if (lazyImages[imgIndex].dataset.src) {
            lazyImages[imgIndex].src = lazyImages[imgIndex].dataset.src;
            lazyImages[imgIndex].removeAttribute('data-src');
            lazyImages[imgIndex].parentElement.classList.remove('_wrap-to-animation')
        } else if (lazyImages[imgIndex].dataset.srcset){
            lazyImages[imgIndex].srcset = lazyImages[imgIndex].dataset.srcset;
            lazyImages[imgIndex].removeAttribute('data-srcset');
            lazyImages[imgIndex].parentElement.classList.remove('_wrap-to-animation')
        }
        delete lazyImagesPositions[imgIndex];
    }
}

function loadMore() {
    const loadMoreBlockPos = loadMoreBlock.getBoundingClientRect().top + pageYOffset;
    const loadMoreBlockHeight = loadMoreBlock.offsetHeight;

    if (pageYOffset > (loadMoreBlockPos + loadMoreBlockHeight) - windowHeight) {
        getContent();
    }
}
