// Form
// import './form/form'

// header
import './header/header'

// post-news-content
import './post-news/post-news-content'

// ? После блоков подключаем основные функции:
// hamburgerOld
import './hamburgerOld/hamburgerOld.js'

// mainFunction
import './mainFunction/mainFunction'

// lazy-load
import './lazy-load/lazy-load'

// dynamicAdapt
import './dynamicAdapt/dynamicAdapt.js'

// Swiper Slider
import './sliderSwiper/sliderSwiper.js'

