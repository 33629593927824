let sliders = document.querySelectorAll('._swiper');
if (sliders) {
	for (let index = 0; index < sliders.length; index++) {
		let slider = sliders[index];
		if (!slider.classList.contains('swiper-bild')) {
			let slider_items = slider.children;
			if (slider_items) {
				for (let index = 0; index < slider_items.length; index++) {
					let el = slider_items[index];
					el.classList.add('swiper-slide');
				}
			}
			let slider_content = slider.innerHTML;
			let slider_wrapper = document.createElement('div');
			slider_wrapper.classList.add('swiper-wrapper');
			slider_wrapper.innerHTML = slider_content;
			slider.innerHTML = '';
			slider.appendChild(slider_wrapper);
			slider.classList.add('swiper-bild');

			if (slider.classList.contains('_swiper_scroll')) {
				let sliderScroll = document.createElement('div');
				sliderScroll.classList.add('swiper-scrollbar');
				slider.appendChild(sliderScroll);
			}
		}
		if (slider.classList.contains('_gallery')) {
			//slider.data('lightGallery').destroy(true);
		}
	}
	sliders_bild_callback();
}

function sliders_bild_callback(params) { }


// cлайдер Автоновости на главной странице
if (document.querySelector('.slider__autonews')) {
	let slider_main_autonews = new Swiper('.slider__autonews', {

		autoHeight: true,
		slidesPerGroup: 1,
		observer: true,
		observeParents: true,
		slidesPerView: 3,
		spaceBetween: 24,
		speed: 500,
		// Arrows
		navigation: {
			nextEl: '.swiper-auto-news__button_next',
			prevEl: '.swiper-auto-news__button_prev',
		},

		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
			559: {
				slidesPerView: 2,
			},
			999: {
				slidesPerView: 3,
			},
		},

		// Отключение предзагрузки картинок
		preloadImages: false,
		// Lazzy Load подгрузка картинок
		lazy: {
			// Подгружать на старте
			// Переключения слайда
			loadOnTransitionStart: true,
			// Подгружать предыдущую и следующую картинку
			loadPrevNext: false,
		},

		// Слежение за видимыми слайдами
		// Следует включать, когда значение slidesPerView = auto или больше, чем 1
		watchSlidesProgress: true,
		// Добавление класса видимым слайдам
		watchSlidesVisibility: true,
	});
}

// cлайдер Происшествия  на главной странице
if (document.querySelector('.slider__incident')) {
	let slider_main_incident = new Swiper('.slider__incident', {

		autoHeight: true,
		slidesPerGroup: 1,
		observer: true,
		observeParents: true,
		slidesPerView: 3,
		spaceBetween: 24,
		speed: 500,
		// Arrows
		navigation: {
			nextEl: '.swiper-incident__button_next',
			prevEl: '.swiper-incident__button_prev',
		},

		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
			559: {
				slidesPerView: 2,
			},
			999: {
				slidesPerView: 3,
			},
		},

		// Отключение предзагрузки картинок
		preloadImages: false,
		// Lazzy Load подгрузка картинок
		lazy: {
			// Подгружать на старте
			// Переключения слайда
			loadOnTransitionStart: true,
			// Подгружать предыдущую и следующую картинку
			loadPrevNext: false,
		},

		// Слежение за видимыми слайдами
		watchSlidesProgress: true,
		// Добавление класса видимым слайдам
		watchSlidesVisibility: true,
	});
}

// cлайдер Хорошие новости на главной странице
if (document.querySelector('.slider__good-news')) {
	let slider_main_goodNews = new Swiper('.slider__good-news', {

		autoHeight: true,
		slidesPerGroup: 1,
		observer: true,
		observeParents: true,
		slidesPerView: 3,
		spaceBetween: 24,
		speed: 500,
		// Arrows
		navigation: {
			nextEl: '.swiper-good-news__button_next',
			prevEl: '.swiper-good-news__button_prev',
		},

		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
			559: {
				slidesPerView: 2,
			},
			999: {
				slidesPerView: 3,
			},
		},

		// Отключение предзагрузки картинок
		preloadImages: false,
		// Lazzy Load подгрузка картинок
		lazy: {
			loadOnTransitionStart: true,
			loadPrevNext: false,
		},

		// Слежение за видимыми слайдами
		watchSlidesProgress: true,
		// Добавление класса видимым слайдам
		watchSlidesVisibility: true,
	});
}

// cлайдер ПЕРВЫЙ внутри новости
if (document.querySelector('.slider-post__slider_first')) {
	let slider_post_first = new Swiper('.slider-post__slider_first', {

		// Эффекты переключения слайдов:
		// Смена прозрачности
		effect: "fade",
		// Дополнения к "fade"
		fadeEffect: {
			// Паралельная смена прозрачности
			crossFade: true,
		},

		slidesPerGroup: 1,
		observer: true,
		observeParents: true,
		slidesPerView: 1,
		speed: 500,
		spaceBetween: 0,
		// Arrows
		navigation: {
			nextEl: '.slider-post__button_right-first ',
			prevEl: '.slider-post__button_left-first ',
		},

		// Навигация
		pagination: {
			// Присваиваем месту вывода пагинации следующий класс, загосим стили в scss слайдера
			el: '.slider-post__fraction_first',

			// Фракции
			type: "fraction",
		},

		// Переключение при клике на слайд
		// slideToClickedSlide: true,
		loop: true,

		// Отключение предзагрузки картинок
		preloadImages: false,
		// Lazzy Load подгрузка картинок
		lazy: {
			loadOnTransitionStart: true,
			loadPrevNext: false,
		},

		// Слежение за видимыми слайдами
		watchSlidesProgress: true,
		// Добавление класса видимым слайдам
		watchSlidesVisibility: true,
	});
}

// cлайдер ВТОРОЙ внутри новости
if (document.querySelector('.slider-post__slider_second')) {
	let slider_post_first = new Swiper('.slider-post__slider_second', {

		// Эффекты переключения слайдов:
		// Смена прозрачности
		effect: "fade",
		// Дополнения к "fade"
		fadeEffect: {
			// Паралельная смена прозрачности
			crossFade: true,
		},

		slidesPerGroup: 1,
		observer: true,
		observeParents: true,
		slidesPerView: 1,
		speed: 500,
		spaceBetween: 0,
		// Arrows
		navigation: {
			nextEl: '.slider-post__button_right-second ',
			prevEl: '.slider-post__button_left-second ',
		},

		// Навигация
		pagination: {
			// Присваиваем месту вывода пагинации следующий класс, загосим стили в scss слайдера
			el: '.slider-post__fraction_second',

			// Фракции
			type: "fraction",
		},

		// Переключение при клике на слайд
		// slideToClickedSlide: true,
		loop: true,

		// Отключение предзагрузки картинок
		preloadImages: false,
		// Lazzy Load подгрузка картинок
		lazy: {
			loadOnTransitionStart: true,
			loadPrevNext: false,
		},

		// Слежение за видимыми слайдами
		watchSlidesProgress: true,
		// Добавление класса видимым слайдам
		watchSlidesVisibility: true,
	});
}

