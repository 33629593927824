import './go-top/go-top'

// swiper slider
import './sliderSwiper/swiper.min.js'

// smoothScroll
import './smoothScroll/smoothScroll.js'

// Magnific Popup
// import './magnific-popup/init'

// Owl Carousel
// import './owl-carousel/init'

// Simple ajax form
// import './simple-ajax-form-submit/jquery.simple-ajax-form'

// Fixed block
// import './fixedblock'
