if (document.querySelector('.links-share')) {

    // Открытие и закрытия меню ссылок на репосты соц.сетей
    let buttonLinkShare = document.querySelector('.links-share');
    let linksList = document.querySelector('.links-share__list');

    buttonLinkShare.addEventListener("click", function (e) {
        linksList.classList.toggle('_active');
        buttonLinkShare.classList.toggle('_active');
    });

    if (linksList.classList.contains('_active') !== true && buttonLinkShare.classList.contains('_active') !== true && window.innerWidth < 479.98) {
        linksList.classList.toggle('_active');
        buttonLinkShare.classList.toggle('_active');
    };

    // Демонстрации меню ссылок на репосты соц.сетей, при наличии рекламы, на больших экранах
    let adBlockRightSideContent = document.querySelector('.main__ad-block_right-side').children;
    if (window.innerWidth > 884.99 && adBlockRightSideContent) {
        window.addEventListener('scroll', () => {
			if (pageYOffset > 600) {
                buttonLinkShare.classList.add('_show-wide-screen')                
			} else {
                buttonLinkShare.classList.remove('_show-wide-screen')
			}
		})
    }
}

if (document.querySelector('.post-news__comments')) {
    // Открытие и закрытие блока комментариев
    let buttonComments = document.querySelector('.post-news__comments');
    let commentsBlock = document.querySelector('.post-news__comments-vk');

    buttonComments.addEventListener("click", function (e) {
        commentsBlock.classList.toggle('_active');
    });
}